<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
       <b-row class="mb-1 mt-1">
          <b-col lg="3" md="3" sm="12">     <b-form-input
                    
            v-model="searchQueryP"
            class="d-inline-block mr-1"
            placeholder="Search Price..." /></b-col>
           
            <b-col lg="3" md="3" sm="12">  
                <b-form-input
                    
              v-model="searchQueryC"
              class="d-inline-block mr-1"
              placeholder="Search Category..." /></b-col>
             
             <b-col lg="3" md="3" sm="12">     <b-form-input
                    
                v-model="searchQueryLink"
                class="d-inline-block mr-1 "
                placeholder="Search Link..." /></b-col>
             
              
        
             <b-col lg="3" md="3" sm="12">      <b-form-input

              v-model="searchQueryCN"
              class="d-inline-block mr-1"
              placeholder="Search Self learning Course Name..."
            /></b-col>
         
        
          <!-- Per Page -->
          <b-col
           lg="6" md="6" sm="12"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
            data-testid="recorded-course-pagesize"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
          
         lg="6" md="6" sm="12"
            class="d-flex align-items-end justify-content-end mb-1 mt-1"
          >
            <b-button
            data-testid="recorded-sync-button"
               v-if="!isLoading"
              variant="primary"
              class="mr-1"
              @click="DeleteRows"
            >
              sync SkillShare Courses
            </b-button>
            
            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
          <b-col cols="12"        class="d-flex align-items-end justify-content-end mb-1 mb-md-0">
            <b-button
    
              variant="primary"
              class="mr-1"
              @click="getData"
            >
              Save Changes
            </b-button>
          </b-col>
         

        </b-row>
      </div>

      <b-table
      data-testid="recorded-courses-table"
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="allData"
     
        show-empty
        :sort-desc.sync="isSortDirDesc"
        empty-text="No matching records found"
        @row-selected="onRowSelected"
      >
        <!-- Column: Status -->
      
  <template #cell(coming_soon)="data">  
  <b-form-group class="mb-0">  
    <!-- <span 
    style="font-weight: bold;  text-align: center"
    v-if="data.item.coming_soon === 1">on</span>  
    <span 
    style="font-weight: bold;  text-align: center"
    v-else-if="data.item.coming_soon === 0">off</span>  
    <span v-else></span>   -->
    
  <!-- <b-form-checkbox v-model="data.item.coming_soon" 
  @change="storeRowId(data.item.recorded_course_id, data.item.coming_soon)" 
  switch>  
   
  </b-form-checkbox>   -->
  <v-select
                v-model="data.item.coming_soon"  
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="  
        [  
          { value: null, text: 'Select Status' },  
          { value: 'available', text: 'Available' },  
          { value: 'coming_soon', text: 'Coming Soon' }  
        ]  
      " 
                  :reduce="(val) => val.value"
                  class="custom-selectt"
                  @input="storeRowId(data.item.recorded_course_id, data.item.coming_soon)" 

                />

  </b-form-group>  
</template>  
        <!-- Column: Actions -->
        <template #cell(actions)="data">

         <a
            target="_blank"
            :href="`https://lpcentre.com/online/self-learning/${data.item.category_link_id}/${data.item.link_id}`"
          >

            <feather-icon icon="LinkIcon" />

          </a>
        
          <a
            v-if="$can('delete', 'course')"
            role="button"
            @click="DeleteCourse(data.item.recorded_course_id)"
          >
            <feather-icon icon="TrashIcon" 
            :data-testid="`delete-item-${data.item.recorded_course_id}`"
            />

          </a>

        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BFormSwitch,  
  BFormGroup,
  BFormCheckbox

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
  BFormGroup,
    BFormSwitch,
     BFormCheckbox,  
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,

    vSelect,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const refCourseListTable = ref(null)
    const searchQueryCN = ref('')
       const searchQueryP = ref("");
         const searchQueryLink = ref("");
    const searchQueryC = ref('')
    const searchQueryD = ref('')
    const totalCategories = ref(0)
    const searchQuery = ref('')
      const isLoading = ref(false)
    const currentPage = ref(1)
    const SortArray = []
    const searchQueryL = ref('')
    const selectedRow = ref([])
    const allData = ref([])
    const sortOptions = ['name', 'duration']
    const sort = ref('')
    const coming = ref(0)
    const courseID = ref("")
    const isSortDirDesc = ref(true)
    const refetchData = () => {
      refCourseListTable.value.refresh()
    }

    const comingSoonData = ref({}); // Using ref to create a reactive object  

const storeRowId = (id, comingSoonValue) => {  
  // Store the value for the specific row ID  
  comingSoonData.value=  comingSoonValue     
  courseID.value = id
  console.log(`Row ID: ${id}, Coming Soon Value: ${comingSoonValue}`);  
};  
    const getData=()=>{
      const formData = new FormData()
          formData.append('coming_soon', comingSoonData.value)
          formData.append('recorded_course_id', courseID.value)

store
       .dispatch('courses/changeComingSoonCourse', formData)
       .then((response) => {
        GetAllCourses()
         Vue.swal({
           title: " Changes saved successfuly ",
           icon: "success",
           customClass: {
             confirmButton: "btn btn-primary",
           },
           buttonsStyling: false,
         });
       })
       .catch((error) => {
         Vue.swal({
           title: "",
           text: `${error.response.data.message}`,
           icon: "error",
           confirmButtonText: "Ok",
           customClass: {
             confirmButton: "btn btn-primary",
           },
           buttonsStyling: false,
         });
       });
}
    const GetAllCourses = () => {
      let data = null
      if (!SortArray.includes(sort.value) && sort.value != '') {
        if (isSortDirDesc.value == true) {
          SortArray.push(sort.value)
          data = SortArray.toString().replace('[', '')
        } else {
          SortArray.push(`-${sort.value}`)
          data = SortArray.toString().replace('[', '')
        }
      }

      store
        .dispatch('courses/getAllCoursesRecordedList', {
          'filter[name]': searchQueryCN.value,
            "filter[price]": searchQueryP.value,
          'filter[category]':searchQueryC.value,
       
        'filter[link_id]':searchQueryLink.value,

          sort: data,
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          console.log(response.data)
          // coursesList.value=response.data.data
          allData.value = response.data.data.map(item => ({  
    ...item,  
    coming_soon: item.coming_soon === "null"? null: item.coming_soon  
}));  
          totalCategories.value = response.data.meta.total
        })
    }
    watch([currentPage, sort, perPage, searchQueryD,
      searchQueryC,
       searchQueryC,
      searchQueryP,
      searchQueryLink,
      searchQueryCN, searchQueryL], () => {
      GetAllCourses()
    })

    GetAllCourses()

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    const selectAllRows = () => {
      if (!selectedRow.value.length > 0) {
        refCourseListTable.value.selectAllRows()
      } else {
        refCourseListTable.value.clearSelected()
      }
    }
    // GetAllCourses()
    const DeleteCourse = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('courses/DeleteCourseRecorded',id).then(response => {
            GetAllCourses()
            Vue.swal({
              title: 'Course Deleted ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }

    const onRowSelected = items => {
      selectedRow.value = items
    }
    const DeleteRows = () => {
        isLoading.value = true
     store.dispatch('courses/SkillShare').then(response => {
            GetAllCourses()
              isLoading.value = false
            Vue.swal({
              title: 'Success ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          
      }).catch(error => {
          isLoading.value = false
                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })})
    }
    const tableColumns = [
      // { key: 'id',lanel:"ID", sortable: true },
      { key: 'recorded_course', label: 'Course Name', sortable: true },
  { label: 'In-Link Name', key: 'link_id', sortable: true },
      {
        key: 'price',
        label: 'price',

        sortable: true,
      },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'coming_soon', label: 'Coming Soon', sortable: true },
     
      { key: 'actions' },
    ]

    return {
      onRowSelected,
      isLoading,
      selectedRow,
      SortArray,
      isSortDirDesc,
      searchQueryL,
      DeleteRows,
      searchQueryD,
      searchQueryC,
      searchQueryCN,
      searchQueryP,
      searchQueryLink,
      selectAllRows,
      tableColumns,
      coursesList,
      DeleteCourse,
      allData,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      refetchData,
      GetAllCourses,
      searchQuery,
      sortOptions,
      sort,
      coming,
      getData,
      comingSoonData, // return this if you need to use it elsewhere  
      storeRowId,
      courseID
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.custom-selectt {  
  width: 181px; /* Adjust to your needs */  
  // border-radius: 0.25rem; /* Example for rounded corners */  
  // background-color: #f8f9fa; /* Example for background color */  
} 
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
